/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { getAuth, isAuthenticated } from '@services/identity.service';
import { isProtected } from './Security';
import * as ProtectedRoutes from './Security';
import PageNotFound from '@components/Error/PageNotFound';
const isBrowser = () => typeof window !== 'undefined';
import useAuthCheck from '@components/Auth/AuthCheck';

const WithAuth = ({ router, children }) => {
  useAuthCheck();
  const auth = getAuth();
  // console.log({ auth });
  if (isBrowser() && !isAuthenticated(auth) && isProtected(router.pathname)) {
    router.replace('/');
  }

  if (isAuthenticated(auth) && !ProtectedRoutes[auth.role](router.pathname)) {
    return <PageNotFound />;
  }
  return children;
};

export default WithAuth;
