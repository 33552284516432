import { useEffect, useState } from 'react';

const useWebSocket = (userId) => {
  const [status, setStatus] = useState('ACTIVE');
  const WS_URL = process.env.NEXT_PUBLIC_WS_URL;
  
  useEffect(() => {
    if (!userId) return; // Don't connect if userId is not available

    // Connect to the WebSocket server
    const ws = new WebSocket(WS_URL);

    // Handle WebSocket connection open
    ws.onopen = () => {
      console.log('WebSocket connected');

      // Send the userId to the server after the connection is established
      ws.send(JSON.stringify({ userId }));
    };

    // Handle incoming messages
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.userId === userId) {
        setStatus(data.status); // Update the user's status
      }
    };

    // Handle WebSocket connection close
    ws.onclose = () => {
      console.log('WebSocket disconnected');
    };

    // Cleanup on unmount
    return () => {
      ws.close();
    };
  }, [userId]);

  return status;
};

export default useWebSocket;
