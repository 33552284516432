import { useEffect } from "react";
import { useRouter } from "next/router";
import {
  getAuth,
  isAuthenticated,
  removeAuth,
} from '@services/identity.service';
import useWebSocket from '@hook/useWebSocket';
const PUBLIC_ROUTES = ['/', '/reset-password', '/reset-password/verify'];

const useAuthCheck = () => {
  const router = useRouter();
  const auth = getAuth();
  const userId = auth?.id; // Get the userId from the auth object
  const status = useWebSocket(userId); // Pass userId to the WebSocket hook

  useEffect(() => {
    if (!isAuthenticated(auth) && !PUBLIC_ROUTES.includes(router.pathname)) {
      router.replace('/'); // Redirect to login if not authenticated
    }
  }, [auth, router]);

  useEffect(() => {
    if (status !== 'ACTIVE') {
      removeAuth(); // Remove session if status is not active
      router.replace('/');
    }
  }, [status, router]);

  return null;
};

export default useAuthCheck;
